<script>
  export let business;
  let isActive = false;

  $: ({ ...business } = business);

  $: onChange(business);

  function onChange(b) {
    isActive = getStorage().find((obj) => obj.Id == b.Id) !== undefined;
  }

  function setStorage(item) {
    localStorage.setItem('lista-favourites', JSON.stringify(item));
  }
  function getStorage() {
    let favourites = JSON.parse(localStorage.getItem('lista-favourites'));
    if (!favourites) favourites = [];
    return favourites;
  }

  function activate(event) {
    let bt = event.srcElement;
    if (bt.tagName !== 'DIV') {
      bt = bt.closest('.favourite-action');
    }
    bt.classList.toggle('active');
    localStorage.setItem('mytime', Date.now());
    let listaFavourites = getStorage();
    if (listaFavourites.length === 0) {
      listaFavourites = [];
      listaFavourites.push(business);
    } else {
      let b = listaFavourites.find((obj) => obj.Id == business.Id);
      if (b !== undefined) {
        listaFavourites = listaFavourites.filter(function (obj) {
          return obj.Id !== business.Id;
        });
      } else {
        listaFavourites.push(business);
      }
    }
    setStorage(listaFavourites);
  }
</script>
<div class="favourite-action" onClick={() => {}}  onKeyPress={activate} class:active={isActive}>
  <svg
    id="heart-svg"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <g id="Group" fill="none" fill-rule="evenodd" transform="translate(0 0)">
      <path
        d="M29.193 5.265c-3.629-3.596-9.432-3.671-13.191-0.288-3.76-3.383-9.561-3.308-13.192 0.288-3.741 3.704-3.741 9.709 0 13.415 1.069 1.059 11.053 10.941 11.053 10.941 1.183 1.172 3.096 1.172 4.278 0 0 0 10.932-10.822 11.053-10.941 3.742-3.706 3.742-9.711-0.001-13.415zM27.768 17.268l-11.053 10.941c-0.393 0.391-1.034 0.391-1.425 0l-11.053-10.941c-2.95-2.92-2.95-7.671 0-10.591 2.844-2.815 7.416-2.914 10.409-0.222l1.356 1.22 1.355-1.22c2.994-2.692 7.566-2.594 10.41 0.222 2.95 2.919 2.95 7.67 0.001 10.591zM9.253 7.501c0.277 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-0.001c-1.794 0-3.249 1.455-3.249 3.249v0.001c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v0c0-2.346 1.901-4.247 4.246-4.249 0.002 0 0.002-0.001 0.004-0.001z"
        id="heart"
      />

      <circle
        class="circle"
        cx="29.5"
        cy="29.5"
        r="1.5"
        stroke="#CD85E7"
        stroke-width="0 "
      />

      <g id="grp7" opacity="0" transform="translate(-10 -6)">
        <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2" />
        <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2" />
      </g>

      <g id="grp6" opacity="0" transform="translate(-17 13)">
        <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2" />
        <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2" />
      </g>

      <g id="grp3" opacity="0" transform="translate(-4 31)">
        <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2" />
        <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2" />
      </g>

      <g id="grp2" opacity="0" transform="translate(34 -5)">
        <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2" />
        <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2" />
      </g>

      <g id="grp5" opacity="0" transform="translate(39 14)">
        <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2" />
        <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2" />
      </g>

      <g id="grp4" opacity="0" transform="translate(25 33)">
        <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2" />
        <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2" />
      </g>

      <g id="grp1" opacity="0" transform="translate(24)">
        <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2" />
        <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2" />
      </g>
    </g>
  </svg>
</div>

<style>
  .favourite-action {
    position: relative;
    width: 30px;
    height: 100%;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    overflow: visible;
    border-radius: 50%;
    transition: 0.2s ease;
  }

  svg:hover {
    opacity: 0.5;
  }

  svg #heart {
    transform-origin: center;
    fill: #fff;
    stroke: #fff;
    stroke-width: 1px;
    transition: 0.2s ease;
  }
  svg .circle {
    transform-origin: 29.5px 29.5px;
  }

  :global(.active) svg #heart {
    transform: scale(0);
    fill: #ff4343;
    stroke: #ff4343;
    animation: heart 0.25s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  .active svg #grp1 {
    opacity: 1;
    transition: 0.1s 0.2s;
  }

  .active svg #grp1 #oval1 {
    transform: scale(0) translate(0, -30px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp1 #oval2 {
    transform: scale(0) translate(10px, -50px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp2 {
    opacity: 1;
    transition: 0.1s 0.2s;
  }
  .active svg #grp2 #oval1 {
    transform: scale(0) translate(30px, -15px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp2 #oval2 {
    transform: scale(0) translate(60px, -15px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp3 {
    opacity: 1;
    transition: 0.1s 0.2s;
  }
  .active svg #grp3 #oval1 {
    transform: scale(0) translate(30px, 0px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp3 #oval2 {
    transform: scale(0) translate(60px, 10px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp4 {
    opacity: 1;
    transition: 0.1s 0.2s;
  }
  .active svg #grp4 #oval1 {
    transform: scale(0) translate(30px, 15px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp4 #oval2 {
    transform: scale(0) translate(40px, 50px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp5 {
    opacity: 1;
    transition: 0.2s all 0.2s;
  }
  .active svg #grp5 #oval1 {
    transform: scale(0) translate(-10px, 20px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp5 #oval2 {
    transform: scale(0) translate(-60px, 30px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp6 {
    opacity: 1;
    transition: 0.2s 0.2s;
  }
  .active svg #grp6 #oval1 {
    transform: scale(0) translate(-30px, 0px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp6 #oval2 {
    transform: scale(0) translate(-60px, -5px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp7 {
    opacity: 1;
    transition: 0.2s 0.2s;
  }
  .active svg #grp7 #oval1 {
    transform: scale(0) translate(-30px, -15px);
    transform-origin: 0 0 0;
    transition: 0.2s transform 0.2s;
  }
  .active svg #grp7 #oval2 {
    transform: scale(0) translate(-55px, -30px);
    transform-origin: 0 0 0;
    transition: 1.2s transform 0.2s;
  }
  .active svg #grp2 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }
  .active svg #grp3 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }
  .active svg #grp4 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }
  .active svg #grp5 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }
  .active svg #grp6 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }
  .active svg #grp7 {
    opacity: 1;
    transition: 0.2s opacity 0.2s;
  }

  @keyframes heart {
    from {
      transform: scale(0.2);
    }
    to {
      transform: scale(1);
    }
  }

  @media (max-width: 600px) {
    .favourite-action {
      width: 25px;
    }
  }
</style>
