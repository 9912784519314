<script>
  import { link } from 'svelte-routing';
  import {
    IconEuro,
    IconFood,
    IconTravel,
    IconHealth,
    IconScissors,
    IconKey,
    IconDollar,
    IconBuy,
    IconJob,
    IconSchool,
    IconSpirituality,
    IconParty,
    IconHelp,
    IconReligion,
    IconIT,
    IconMaintenance,
    IconTransfers,
    IconGarage,
    IconProfessionals,
    IconMarketing,
    IconClothes,
    IconTags,
    IconTranslate,
  } from './icons';

  export let Icon;
  export let Name;
  export let Url;
</script>

<IconEuro />
<IconFood />
<IconTravel />
<IconHealth />
<IconScissors />
<IconKey />
<IconDollar />
<IconBuy />
<IconJob />
<IconSchool />
<IconSpirituality />
<IconParty />
<IconHelp />
<IconReligion />
<IconIT />
<IconMaintenance />
<IconTransfers />
<IconGarage />
<IconProfessionals />
<IconMarketing />
<IconClothes />
<IconTags />
<IconTranslate />
<div class="item">
  <a href={Url} use:link>
    <div class="item-content">
      <svg class="icon"><use xlink:href="#icon-{Icon}" /></svg>
      <h3>{Name}</h3>
    </div>
  </a>
</div>

<style lang="scss">
  @import '../style/theme.scss';
  .item-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .item {
    a {
      border-radius: $border-radius;
      height: 285px;
      transition: all 0.3s ease-in-out;
      display: block;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 60px 20px;
      text-align: center;
      position: relative;
      margin-bottom: 25px;
      .icon {
        font-size: 70px;
        margin-bottom: 20px;
        transition: all 0.3s ease-in-out;
        color: $main-color;
      }
      &:hover {
        box-shadow: 0 20px 20px -20px;
      }
    }
    h3 {
      font-size: 1.125rem;
      margin: 0;
      color: #222;
      font-weight: 500;
    }
    .icon {
      fill: $main-color;
      height: 75px;
      width: 75px;
    }
  }
</style>
