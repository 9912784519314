<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import auth from '../authService.js';
  import { isAuthenticated, user } from '../utils/store.js';
  let metadata, mainSearchParams;
  let auth0Client;
  onMount(async () => {
    auth0Client = await auth.createClient();
    isAuthenticated.set(await auth0Client.isAuthenticated());
    user.set(await auth0Client.getUser());

    if ($isAuthenticated) {
      console.log($user);
    } else {
      console.log('Not authenticated');
    }

    metadata = {
      title: 'Sua conta do Lista Brasil',
      url: window.location.href,
    };
    mainSearchParams = {
      title: metadata.title,
      showSearch: false,
      height: '300px',
    };
  });

  function login() {
    auth.loginWithPopup(auth0Client);
  }

  function logout() {
    auth.logout(auth0Client);
  }

  const callApi = async () => {
    try {
      // Get the access token from the Auth0 client
      const token = await auth0Client.getTokenSilently();

      // Make the call to the API, setting the token
      // in the Authorization header
      const response = await fetch('http://localhost:7071/protected', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Fetch the JSON result
      const responseData = await response.json();

      console.log(responseData);
    } catch (e) {
      // Display errors in the console
      console.error(e);
    }
  };
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section class="section-gray">
  <div class="container">
    {#if $isAuthenticated}
      <li class="nav-item">
        <a class="nav-link" href="#a" on:click={logout}>Log Out</a>
        <button id="btn-call-api" on:click={callApi}>Call Api</button>
      </li>
    {:else}
      <li class="nav-item">
        <a class="nav-link" href="#a" on:click={login}>Log In</a>
      </li>
    {/if}
  </div>
</section>
<Footer />

<style type="text/scss">
  @import '../style/theme.scss';
</style>
