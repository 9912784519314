<script>
  import { link } from 'svelte-routing';
  import { ToSeo } from '../utils/ToSeo.svelte';
  import { GetDirections } from '../utils/GetDirections.svelte';
  export let business;
  export let origin;

  let mapUrl = '';
  let businessBackgroundImage = '';

  $: onChange(business);

  function onChange(b) {
    businessBackgroundImage = '/images/placeholder/placeholder.svg';
    mapUrl = GetDirections(b, origin).mapUrl;
    let url = `/pt/empresas-e-servicos/b/${b.Id}/${ToSeo(b.Title)}`;
    business.Url = url;

    if (
      b.UserId !== undefined &&
      b.BusinessLogo !== undefined &&
      b.BusinessLogo !== ''
    ) {
      businessBackgroundImage = `https://cdn.listabrasil.com/user/${b.UserId}/l-${b.BusinessLogo}`;
    }
  }
</script>

<div class="item">
  {#if mapUrl}
    <a href={mapUrl} class="view-map">
      <svg class="icon"><use xlink:href="#icon-current-location" /></svg></a
    >{/if}
  <a class="item-details" href={business.Url} use:link>
    <span>{business.CategoryName}</span>
    {#if business.IsHomePage}
      <span class="premium">Destaque</span>
    {/if}
    <div class="item-content">
      <h3>{business.Title}</h3>
      <p class="to-upper">
        {#if business.Postcode}
          {business.Postcode} -
        {/if}
        {business.City}
      </p>
      {#if business.Distance}
        <p>
          Distância: {Math.round((business.Distance + Number.EPSILON) * 100) /
            100}KM
        </p>
      {/if}
    </div>
    <div
      class="image"
      style="background-image: url({businessBackgroundImage})"
    />
  </a>
</div>

<style lang="scss">
  @import '../style/theme.scss';
  .to-upper {
    text-transform: uppercase;
  }
  .view-map {
    position: absolute;
    z-index: 3;
    bottom: 40px;
    right: 10px;
    fill: white;
    .icon {
      width: 35px;
      height: 30px;
      transition: all 0.2s ease-out;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
  .item {
    position: relative;
    .item-details {
      border-radius: $border-radius;
      height: 200px;
      transition: all 0.3s ease-in-out;
      display: block;
      width: 100%;
      text-align: left;
      background-color: #ededed;
      padding: 60px 20px;
      position: relative;
      margin-bottom: 25px;
      overflow: hidden;
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        transition: all 0.3s ease-in-out;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: darken(#2d91d6, 10%);
      }
      &:hover .image {
        transform: scale(1.2);
      }
      &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
        background-image: linear-gradient(transparent, black);
        z-index: 2;
      }
    }
    span {
      position: absolute;
      left: 15px;
      top: 15px;
      display: inline-block;
      background-color: black;
      color: white;
      font-size: 0.65rem;
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      z-index: 3;
      padding: 5px;
      border-radius: $border-radius;
    }
    .premium {
      background-color: $main-color;
      right: 15px;
      left: auto;
    }
    h3 {
      font-size: 1.025rem;
      margin: 0;
      font-weight: 500;
    }
    :global(.item-content p) {
      font-size: 0.84rem;
    }
  }
  .item-content {
    width: 83%;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    z-index: 3;
  }
</style>
