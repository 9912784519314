<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import { lists, settings } from '../utils/store';
  import Teaser from '../components/Teaser.svelte';
  import Footer from '../components/Footer.svelte';
  import { navigate } from 'svelte-routing';
  import Breadcrumbs from '../components/Breadcrumbs.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Loading from '../components/Loading.svelte';
  import BusinessesFilter from '../components/BusinessesFilter.svelte';
  import RouteTest from '../utils/RouteTest.svelte';
  import CardBusiness from '../components/CardBusiness.svelte';
  import { ToSeo } from '../utils/ToSeo.svelte';
  import { IconTarget } from '../components/icons';
  let metadata, breadcrumbsData;

  export let location;
  export let geoinfo;
  export let categoryId;
  export let categoryTitle;

  let locationFormatted = location;

  let category;
  $: businesses = $lists.filtered;

  let loadFilters = false;
  let homeUrl = '/';

  let footerData = {
    location,
    geoinfo,
  };

  let routeData = {
    location: location,
    geoInfo: geoinfo,
    id: categoryId,
    url: categoryTitle,
  };

  let dataFetchComplete = false;

  const searchParamsStorageName = 'lista-search';
  function getStorage() {
    let storageItem = JSON.parse(localStorage.getItem(searchParamsStorageName));
    if (!storageItem) storageItem = {};
    return storageItem;
  }

  function setStorage(item) {
    localStorage.setItem(searchParamsStorageName, JSON.stringify(item));
  }

  function setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (p) {
          let lat = p.coords.latitude;
          let lng = p.coords.longitude;
          const geocoder = new google.maps.Geocoder();
          const latlng = { lat, lng };
          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                const placeAddress = results[0].address_components.find(
                  (addr) => addr.types[0] === 'postal_code',
                ).short_name;
                lat = parseFloat(lat).toFixed(3);
                lng = parseFloat(lng).toFixed(3);
                setStorage({ lat, lng, placeAddress });
                let redirectUrl = `/pt/${ToSeo(
                  placeAddress,
                )}/empresas-e-servicos/c/${categoryId}/${categoryTitle}/@${lat},${lng}`;
                window.location.href = redirectUrl;
              } else {
                //;
              }
            } else {
              //;
            }
          });
        },
        function (error) {
          //alert(error.code);
        },
      );
    }
  }

  onMount(async () => {
    let searchParams = getStorage();
    if (Object.keys(searchParams).length > 0) {
      geoinfo = `@${searchParams.lat},${searchParams.lng}`;
      location = ToSeo(searchParams.placeAddress);
      locationFormatted = searchParams.placeAddress;
    }
    const app = document.getElementById('app');
    app.classList.add('always-sticky');

    if (categoryId > 0) {
      await fetch(`${$settings.apiUrl}/category/${categoryId}`)
        .then((r) => {
          if (r.status >= 400 && r.status < 600)
            navigate('/conteudo-nao-encontrado', { replace: true });
          return r;
        })
        .then((r) => r.json())
        .then((data) => {
          category = data.Data[0];
          metaData(category);
          breadcrumbsData = {
            links: [
              { url: homeUrl, title: 'Home' },
              {
                url: `/pt/${location}/empresas-e-servicos/${geoinfo}`,
                title: 'Empresas e serviços',
              },
              { url: window.location.href, title: category.Name },
            ],
            pageTitle: populateWithCity(category.Title),
          };
        });
    } else {
      let pageTitle = 'Resultado da busca';
      metaData({
        Title: pageTitle,
        Description: `Página mostrando os resultados de busca pelo termo: ${categoryTitle}`,
        Keywords: `resultado da busca, ${categoryTitle}, lista brasil`,
      });
      breadcrumbsData = {
        links: [
          { url: homeUrl, title: 'Home' },
          { url: window.location.href, title: pageTitle },
        ],
        pageTitle: pageTitle,
      };
    }

    let apiBusinessUrl = `${$settings.apiUrl}/business/${geoinfo}/${categoryId}`;
    if (categoryId == 0) {
      apiBusinessUrl = `${$settings.apiUrl}/business/${geoinfo}/${categoryId}/${categoryTitle}`;
    }

    await fetch(apiBusinessUrl)
      .then((r) => {
        if (r.status >= 400 && r.status < 600) console.log('Nothing was found');
        return r;
      })
      .then((r) => r.json())
      .then((data) => {
        if (Array.isArray(data)) {
          businesses = data;
        } else {
          businesses = data.Data;
        }

        dataFetchComplete = true;
        let i;
        for (i = 0; i < businesses.length; i++) {
          let business = businesses[i];
          let url = `/pt/empresas-e-servicos/b/${business.Id}/${business.Url}`;
          business.Url = url;
          if (businesses.length - 1 === i) {
            loadFilters = true;
          }
        }
      });
  });

  function metaData(category) {
    metadata = {
      title: populateWithCity(category.Title),
      description: populateWithCity(category.Description),
      keywords: populateWithCity(category.Keywords),
      url: window.location.href,
    };
  }

  function populateWithCity(data) {
    return data.replace(', {0}', '');
  }
</script>

<IconTarget />
<RouteTest {...routeData} />
<Meta {metadata} />
<HeaderBar isAlwaysSticky="true" />
{#if breadcrumbsData}
  <Breadcrumbs {...breadcrumbsData} />
{:else}
  <Loading />
{/if}
{#if loadFilters}
  <BusinessesFilter {businesses} />
{/if}
<section>
  <div class="container">
    <div class="header-two left">
      <span><em /></span>
      <p>
        {#if dataFetchComplete}
          {#if businesses.length > 0}
            Resultados relevantes para <strong>{locationFormatted}</strong>
          {:else if businesses.length === 0}
            Nenhum resultado foi encontrado
          {/if}
          <svg class="icon" onClick={() => {}} onKeyPress={setCurrentLocation}
            ><use xlink:href="#icon-target" /></svg
          >
        {:else}
          <Loading />
        {/if}
      </p>
    </div>
    <div id="items-container" class="items-list">
      {#each businesses as business}
        <CardBusiness {business} origin={geoinfo} />
      {/each}
    </div>
  </div>
</section>
<Teaser />
<Footer {...footerData} />

<style lang="scss">
  @import '../style/theme.scss';

  .items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 20px;
    transition: all 0.3s ease-in-out;
  }

  .icon {
    $size: 30px;
    fill: $dark-color;
    height: $size;
    width: $size;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
      fill: $main-color;
    }
  }

  @media (max-width: 800px) {
    .items-list {
      grid-template-columns: 1fr;
    }
  }
</style>
