<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  let metadata, mainSearchParams;
  onMount(() => {
    metadata = {
      title: 'Anuncie no Lista Brasil',
      description:
        'Promova sua empresa ou serviço para milhares de pessoas diariamente',
      url: window.location.href,
    };
    mainSearchParams = {
      title: metadata.title,
      subTitle: metadata.description,
      showSearch: false,
      height: '350px',
    };
  });
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section class="section-gray">
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Porque anunciar no Lista Brasil?</h2>
      <p>Aumente sua visibilidade na Internet</p>
    </div>
    <div class="double-box">
      <div class="text-box">
        <h3>Milhares de visitantes</h3>
        <h4>
          O Lista Brasil é uma das principais referências online para
          Brasileiros vivendo, ou que desejam viver fora do Brasil.
        </h4>
        <p>
          Visitado diariamente por milhares de pessoas, desde <strong
            >2008</strong
          >, o Lista Brasil possui mais de
          <strong>5000 empresas e serviços</strong>
          cadastrados. O site é visitado mensalmente por pessoas em mais de
          <strong>150 países</strong> ao redor do mundo.
        </p>
      </div>
      <div class="image-box">
        <img src="/images/boost.svg" alt="Marketing Digital" />
      </div>
    </div>
    <div class="double-box">
      <div class="image-box">
        <img src="/images/manage.svg" alt="Gerencie seu anúncio" />
      </div>
      <div class="text-box">
        <h3>Facilidade de administração</h3>
        <h4>
          O Lista Brasil fornece uma série de ferramentas muito úteis para
          melhorar o seu anúncio.
        </h4>
        <p>
          Você receberá uma senha para atualizar o seu anúncio a qualquer
          momento. Avise os visitantes sobre <strong
            >promoções e ofertas relâmpago</strong
          >
        </p>
        <p>
          Tenha uma bela <strong>galeria de fotos</strong> em seu anúncio, mapa
          com a <strong>localização</strong>
          da sua empresa, <strong>formulário de contato</strong> e muito mais.
        </p>
      </div>
    </div>
    <div class="double-box">
      <div class="text-box">
        <h3>Ganhe novos clientes</h3>
        <h4>
          O Lista Brasil utiliza ferramentas para otimização do nosso conteúdo.
        </h4>
        <p>
          Todos os meses o Lista Brasil recebe uma porcentagem muito alta de
          <strong>novos visitantes</strong>.
        </p>
        <p>
          Estamos sempre atualizando e <strong>otimizando</strong> o Lista
          Brasil utilizando as mais novas técnicas de
          <strong>aperfeiçoamento de marketing digital</strong>.
        </p>
      </div>
      <div class="image-box">
        <img src="/images/visitors.svg" alt="Novos visitantes" />
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Nossos planos de anúncio</h2>
      <p>Escolha o melhor plano para atender as suas necessidades</p>
    </div>
    <div class="packages">
      <div>
        <a href="#a">
          <p class="price"><small>£</small>0</p>
          <h3>Básico</h3>
          <ul>
            <li>Anúncio válido por 1 (um) mês</li>
            <li>1 (um) anúncio por conta</li>
            <li>Descrição</li>
            <li>Mapa</li>
            <li>Contato</li>
          </ul>
        </a>
      </div>
      <div>
        <a href="#a">
          <p class="price"><small>£</small>4.99</p>
          <h3>Profissional</h3>
          <ul>
            <li>Anúncio não expira</li>
            <li>Múltiplos anúncios por conta</li>
            <li>Descrição</li>
            <li>Mapa</li>
            <li>Contato</li>
            <li>Logo</li>
            <li>Sem anúncios relacionados</li>
          </ul>
        </a>
      </div>
      <div>
        <a href="#a">
          <p class="price"><small>£</small>9.99</p>
          <h3>Premium</h3>
          <ul>
            <li>Anúncio não expira</li>
            <li>Múltiplos anúncios por conta</li>
            <li>Descrição</li>
            <li>Mapa</li>
            <li>Contato</li>
            <li>Logo</li>
            <li>Sem anúncios relacionados</li>
            <li>Destaque</li>
            <li>Home page</li>
          </ul>
        </a>
      </div>
    </div>
  </div>
</section>
<Footer />

<style type="text/scss">
  @import '../style/theme.scss';
  .double-box {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .text-box {
    background: #fff;
    border-radius: $border-radius;
    padding: 50px;
    p {
      display: none;
    }
  }
  .image-box {
    display: none;
    text-align: center;
    padding: 30px;
    img {
      height: 335px;
      margin: auto;
    }
  }
  .price {
    font-size: 3em;
    small {
      font-size: 0.5em;
      margin-right: 10px;
    }
  }
  .packages {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
    div {
      padding: 50px;
      margin-bottom: 50px;
      text-align: center;
      background: #459cdc;
      border-radius: $border-radius;
      color: #fff;
      &:hover {
        background: #0d7fd2;
      }
    }
    ul {
      margin: 20px 0;
    }
    li {
      line-height: 3em;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #fff;
    }
  }
  h3 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
  }
  p {
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 850px) {
    .image-box {
      display: block;
    }
    .double-box {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      p {
        display: block;
      }
    }
    .packages {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 50px;
    }
  }
</style>
