<script>
  import { IconCurrentLocation, IconShare } from '../components/icons';
  import { navigate } from 'svelte-routing';
  import Meta from '../utils/Meta.svelte';
  import { settings } from '../utils/store';
  import Teaser from '../components/Teaser.svelte';
  import BusinessList from '../components/BusinessList.svelte';
  import Favourite from '../components/Favourite.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Breadcrumbs from '../components/Breadcrumbs.svelte';
  import Loading from '../components/Loading.svelte';
  import { GetDirections } from '../utils/GetDirections.svelte';
  export let id;
  export let title;

  let business;
  let metadata;
  let breadcrumbsData;
  let businessListData;

  let showFormErrors;
  let emailSent;
  let contactName;
  let contactEmail;
  let contactEmailPlaceholder;
  let contactTelephone;
  let contactMessage;

  const searchParamsStorageName = 'lista-search';

  $: onChange(id, title);

  function onChange() {
    mount();
  }

  function getStorage() {
    let storageItem = JSON.parse(localStorage.getItem(searchParamsStorageName));
    if (!storageItem) storageItem = {};
    return storageItem;
  }

  async function doPost() {
    showFormErrors = true;
    if (!contactEmail) {
      contactEmailPlaceholder = 'Email é necessário';
    } else if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        contactEmail,
      )
    ) {
      //Valid email address
    } else {
      contactEmail = null;
      contactEmailPlaceholder = 'Email inválido';
    }

    if (contactName && contactEmail && contactMessage) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute('6LfX4LYaAAAAAIYvstctJYlCQ_Tf0aPKM-JUpwVt', {
            action: 'contact',
          })
          .then(function (token) {
            finishSubmit(token);
          });
      });
    }
  }

  async function finishSubmit(token) {
    const res = await fetch(
      `${$settings.apiUrl}/contact/${business.Id}/${title}`,
      {
        method: 'POST',
        body: JSON.stringify({
          name: contactName,
          email: contactEmail,
          telephone: contactTelephone,
          message: contactMessage,
          url: window.location.href,
          recaptchaToken: token,
        }),
      },
    );

    const result = await res.json();
    if (result.response) {
      emailSent = result.response;
    } else {
      console.log(result);
    }
  }

  function sharePage() {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // fallback
    }
  }
  async function mount() {
    business = null;
    metadata = null;
    breadcrumbsData = null;
    showFormErrors = false;
    emailSent = false;
    contactName = null;
    contactEmail = null;
    contactEmailPlaceholder = 'Email';
    contactTelephone = null;
    contactMessage = null;
    let apiUrl = `${$settings.apiUrl}/business/${id}/${title}`;
    let lat = '51.509865';
    let lng = '-0.118092';
    let searchParams = getStorage();
    if (Object.keys(searchParams).length > 0) {
      lat = searchParams.lat;
      lng = searchParams.lng;
    }

    await fetch(apiUrl)
      .then((r) => {
        if (r.status >= 400 && r.status < 600)
          navigate('/conteudo-nao-encontrado', { replace: true });
        return r;
      })
      .then((r) => r.json())
      .then((data) => {
        if (data.Data.length < 1) {
          navigate('/conteudo-nao-encontrado', { replace: true });
        }
        business = data.Data[0];
        if (lat === '' && business.lat !== undefined) lat = business.lat;
        if (lng === '' && business.lng !== undefined) lng = business.lng;

        let businessDescription =
          business.Description.length > 160
            ? business.Description.substring(0, 160) + '...'
            : business.Description.substring(0, 160);
        metadata = {
          title: business.Title,
          description: businessDescription,
          keywords: business.Keywords,
        };
        breadcrumbsData = {
          links: [
            { url: '/', title: 'Home' },
            {
              url: `/pt/${business.CitySeo}/empresas-e-servicos/@${lat},${lng}`,
              title: 'Empresas e serviços',
            },
            {
              url: `/pt/${business.CitySeo}/empresas-e-servicos/c/${
                business.CategoryId
              }/${business.CategoryUrl.replace(
                'internal-to-be-replaced',
                business.CitySeo,
              )}/@${lat},${lng}`,
              title: business.CategoryName,
            },
          ],
          pageTitle: business.Title,
        };
      });
    await fetch(
      `${$settings.apiUrl}/business/related/@${lat},${lng}/${business.CategoryId}/${business.Id}`,
    )
      .then((r) => r.json())
      .then((data) => {
        businessListData = data.Data;
      });
  }
</script>

<IconCurrentLocation />
<IconShare />
{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
<section
  class="main-hero banner"
  style="background-image: url(/images/banners/main-banner.svg);background-color:#fff;height:350px"
>
  <div class="overlay">
    <div class="container">
      {#if business}
        <div class="info-box">
          <div>
            {#if business.IsHomePage}
              <span>DESTAQUE</span>
            {/if}
            <h1>{business.Title}</h1>
            <p>
              {#if business.Postcode}
                {business.Postcode} -
              {/if}{business.City}
            </p>
          </div>
          <div>
            <ul>
              <li>
                {#if GetDirections(business).mapUrl}
                  <a href={business.mapUrl} title="Ver no mapa">
                    <svg class="icon"
                      ><use xlink:href="#icon-current-location" /></svg
                    ></a
                  >
                {/if}
              </li>
              <li>
                <svg class="icon" onClick={() => {}} onKeyPress={sharePage}
                  ><use xlink:href="#icon-share" /></svg
                >
              </li>
              <li>
                {#if business}
                  <Favourite {business} />
                {/if}
              </li>
            </ul>
          </div>
        </div>
      {/if}
    </div>
  </div>
</section>
{#if breadcrumbsData}
  <Breadcrumbs {...breadcrumbsData} />
{:else}
  <Loading />
{/if}
<section>
  <div class="container">
    {#if business}
      <h2>Informações<span><em /></span></h2>
      <div class="business-info">
        {business.Description}
      </div>
      <div class="contact-details">
        <div class="gray-box">
          <h3>Endereço:</h3>
          <div>
            {#if business.Address}
              {business.Address}<br />
            {/if}
            {#if business.Postcode}
              {business.Postcode}<br />
            {/if}
            {business.City}<br />
            {#if GetDirections(business).mapUrl}
              <a href={business.mapUrl} title="Ver no mapa">
                <span class="btn-secondary">Ver no mapa</span></a
              >
            {/if}
          </div>
          <h3>Contato:</h3>
          <div>
            {#if business.Telephone}
              <strong>Telefone:</strong>
              <a href="tel:{business.Telephone.replace('-', '')}"
                >{business.Telephone}</a
              >
              <br />
            {/if}
            {#if business.Url}
              <a
                class="btn-secondary"
                href={business.Url}
                title="Visite {business.Title} website">Visite o website</a
              >
            {/if}
          </div>
        </div>
        <div class="contact-form">
          <div class="form-title">
            <h3>Envie uma mensagem</h3>
            {#if business.Telephone}
              <a href="tel:{business.Telephone.replace('-', '')}"
                >Ou ligue: {business.Telephone}</a
              >
            {/if}
          </div>
          <form>
            {#if emailSent}
              <div class="confirmation-message">
                Sua mensagem foi enviada com sucesso, obrigado!
              </div>
            {:else}
              <input
                type="text"
                bind:value={contactName}
                id="name"
                placeholder={showFormErrors && !contactName
                  ? `Nome é necessário`
                  : `Nome`}
                class:error={showFormErrors && !contactName}
              />
              <input
                type="email"
                bind:value={contactEmail}
                id="email"
                placeholder={contactEmailPlaceholder}
                class:error={showFormErrors && !contactEmail}
              />
              <input
                type="tel"
                bind:value={contactTelephone}
                id="telephone"
                placeholder="Telefone"
              />
              <textarea
                id="message"
                bind:value={contactMessage}
                placeholder={showFormErrors && !contactMessage
                  ? `Mensagem é necessária`
                  : `Mensagem`}
                class:error={showFormErrors && !contactMessage}
              />
              <button class="btn btn-primary" on:click|preventDefault={doPost}
                >Enviar</button
              >
              <div class="small">
                Lista Brasil é protegido por reCAPTCHA. Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                e
                <a href="https://policies.google.com/terms">Terms of Service</a>
                são aplicados.
              </div>
            {/if}
          </form>
        </div>
      </div>
    {/if}
  </div>
</section>
{#if business && !business.IsHomePage}
  <section>
    <div class="container">
      <h2>Similares<span><em /></span></h2>
    </div>
    <BusinessList {businessListData} />
  </section>
{/if}
<Teaser />
<Footer />

<style type="text/scss">
  @import '../style/theme.scss';
  .confirmation-message {
    text-align: center;
    margin-top: 50px;
    text-transform: uppercase;
    color: $main-color;
  }
  .small {
    text-align: center;
    padding: 10px;
    color: darken($gray-color, 25%);
  }
  .icon {
    fill: #fff;
    height: 30px;
    width: 30px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .btn {
    width: 100%;
  }
  .contact-details {
    form {
      padding: 20px;
    }
    input,
    textarea {
      border: dotted 2px darken($gray-color, 10%);
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      &:focus {
        outline: none;
        border-color: $main-color;
      }
    }
    .error {
      border-color: red !important;
    }
    textarea {
      resize: vertical;
      height: 120px;
    }
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 30px;
    column-gap: 10px;
    .form-title {
      border-bottom: dotted 2px darken($gray-color, 10%);
      background: $gray-color;
      padding: 20px;
      text-align: center;
      h3 {
        margin-bottom: 0;
      }
      a {
        font-size: 0.9em;
      }
    }
    .contact-form {
      border: dotted 2px darken($gray-color, 10%);
      height: 100%;
    }
  }
  .btn-secondary {
    display: inline-block;
    border-radius: $border-radius;
    padding: 3px 9px;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-top: 10px;
    background: $main-color;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: darken($main-color, 20%);
    }
  }
  h3 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  .gray-box {
    background-color: $gray-color;
    padding: 30px;

    div {
      margin-bottom: 30px;
    }
    strong {
      padding-right: 20px;
    }
  }
  h2 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.1em;
    padding-bottom: 5px;
    position: relative;
    span {
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $gray-color;
      em {
        background: $main-color;
        height: 100%;
        width: 150px;
        display: block;
      }
    }
  }
  .business-info {
    margin-top: 30px;
  }
  .main-hero {
    width: 100%;
    position: relative;
    text-align: center;
    margin: 0;
    color: #fff;
    background-position: center;
  }
  .banner {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .overlay {
    background-image: linear-gradient(transparent, transparent, black);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }
  .container {
    height: 100%;
  }
  .info-box {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 100%;
    padding: inherit;
    ul {
      display: grid;
      position: absolute;
      right: 0;
      bottom: 0;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 30px;
      justify-items: center;
    }

    h1 {
      font-size: 2em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
    span {
      background-color: $blue-color;
      padding: 5px 10px;
      border-radius: $border-radius;
      font-size: 0.9em;
      display: inline-block;
    }
    position: absolute;
    bottom: 40px;
    left: 0;
    text-align: left;
    div {
      position: relative;
    }
  }
  @media (max-width: 770px) {
    .main-hero {
      height: 320px !important;
    }
    .info-box {
      grid-template-columns: 100%;
      ul {
        margin-top: 30px;
        position: relative;
        justify-content: center;
      }
    }
  }
  @media (max-width: 600px) {
    .info-box {
      h1 {
        font-size: 1.6em;
      }
      span {
        font-size: 0.7em;
      }
      p {
        font-size: 0.9em;
      }
      .icon {
        $size: 25px;
        height: $size;
        width: $size;
      }
    }
  }
  @media (max-width: 990px) {
    .contact-details {
      grid-template-columns: 1fr;
    }
    .contact-form {
      margin-top: 30px;
    }
  }
</style>
