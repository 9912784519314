<script>
  import Meta from '../utils/Meta.svelte';
  import { link } from 'svelte-routing';
  import { onMount } from 'svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Teaser from '../components/Teaser.svelte';
  let metadata, mainSearchParams, favourites;
  onMount(() => {
    metadata = {
      title: 'Política de Privacidade',
      description:
        'As informações fornecidas estão asseguradas de acordo com este termo de privacidade',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Política de Privacidade',
      showSearch: false,
      height: '350px',
    };
  });
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section>
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Política de Privacidade do Lista Brasil</h2>
      <p>
        As informações fornecidas estão asseguradas de acordo com este termo de
        privacidade
      </p>
    </div>
  </div>
  <div class="container text-section">
    <p>
      O <strong>Lista Brasil</strong> se compromte a assegurar que sua privacidade
      é protegida. Toda e qualquer informção fornecida por você está assegurada de
      acordo com este termo de privacidade.
    </p>
    <p>
      O <strong>Lista Brasil</strong> pode mudar essa política de privacidade, fazendo
      atualizações a esta página. Você deve checar essa página de tempos em tempos
      para ter certeza que está em conformidade com qualquer mudança nestes termos.
    </p>
    <h3>Informações coletadas</h3>
    <p>Nós talvez coletamos as seguintes informações:</p>
    <ul>
      <li>Nome</li>
      <li>Informações de contato e/ou email</li>
      <li>Informações de localização, como endereços</li>
      <li>Comentários enviados utilizando formulários do site</li>
      <li>Outras informações relevantes a questionários e/ou ofertas</li>
    </ul>
    <h3>O que fazemos com as informações coletadas</h3>
    <p>
      Estas informações são requeridas para melhor entender suas necessidades e
      prestar um serviço mais adequado e em particular pelas específicas razões:
    </p>
    <ul>
      <li>Cadastro interno</li>
      <li>
        Podemos usar as informações para melhorar nossos produtos e serviços
      </li>
      <li>
        Podemos as vezes enviar email promocional sobre novos produtos, ofertas
        especiais ou outras informações que julgarmos de seu interesse usando o
        email por você fornecido
      </li>
    </ul>
    <h3>Segurança</h3>
    <p>
      Estamos comprometidos em assegurar que suas informações estejam seguras.
      Para evitar acesso não autorizado suas informações estão armazenadas em
      local que utiliza procedimentos físicos, eletrônicos e gerenciais que
      visam a segurança das informações coletadas online.
    </p>
    <h3>Como usamos cookies</h3>
    <p>
      Um cookie é um pequeno arquivo que precisa de permissão para ser salvo no
      disco rígido de seu computador. Estando uma vez de acordo, o arquivo é
      adicionado e auxilia a analisar o tráfego de Internet ou informa quando
      você visita determinado site. Cookies ajudam aplicações web a reconhecer
      você como indivíduo. Essas aplicações web podem usar essas informações
      para saber o que você gosta, não gosta e quais são suas preferências.
    </p>
    <p>
      Cookies nos ajudam a fazer um website melhor, nos permitindo monitorar
      quais páginas você julga importantes e quais não. Cookies, em nenhuma
      situação, dão acesso a informações contidas em seu computador.
    </p>
    <p>
      Você pode aceitar ou rejeitar cookies. A maioria dos navegadores
      automaticamente aceita cookies, mas você pode modificar as configurações
      do seu navegador para não aceitá-los.
    </p>
    <h3>Links para outros websites</h3>
    <p>
      Nosso site contém links que possibilitam que você visite outros websites
      de seu interesse facilmente. No entanto, uma vez usados estes links para
      deixar o <strong>Lista Brasil</strong> você deve ter em mente que nós não temos
      qualquer controle sobre este outro website, e portanto não podemos nos responsabilizar
      pela proteção e privacidade de qualquer informação que você forneca visitando-os.
      Lembre-se que eles não são regidos por estes termos de privacidade. Você deve
      ter o cuidado de ler os termos de privacidade do website em questão.
    </p>
    <h3>Controlando suas informações pessoais</h3>
    <p>
      Nós não vendemos, distribuimos ou alugamos suas informações pessoais para
      terceiros a não ser que tenhamos sua permissão ou que seja requerido por
      lei.
    </p>
    <p>
      Se você acredita que as informações que temos sobre você são incorretas ou
      incompletas, <a use:link href="/entre-em-contato"
        >entre em contato com o Lista Brasil</a
      > o mais breve possível. Nós tomaremos as medidas necessárias.
    </p>
  </div>
</section>
<Teaser />
<Footer />
