<script>
  import { link } from 'svelte-routing';
</script>

<section>
  <div class="container">
    <div class="teaser">
      <div class="teaser-text">
        <p class="small">Faça parte do Lista Brasil</p>
        <h3>Você tem uma empresa ou oferece um serviço fora do Brasil?</h3>
        <p>
          O Lista Brasil é o maior site de empresas e serviços para brasileiros
          no exterior. Junte-se a nós e aumente a sua visibilidade na Internet.
        </p>
        <a href="/anuncie-no-lista-brasil" use:link class="btn btn-primary"
          >Saiba mais</a
        >
      </div>
    </div>
  </div>
</section>

<style type="text/scss">
  @import '../style/theme.scss';
  section {
    background: #fff;
    margin-bottom: 40px;
  }
  .teaser {
    background-color: $dark-color;
    position: relative;
    width: 100%;
    border-radius: $border-radius;
    padding: 60px;
  }
  .teaser-text {
    color: #fff;
  }
  h3 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-size: 1rem;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  a {
    margin-top: 40px;
  }
</style>
