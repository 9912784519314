<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  let metadata, mainSearchParams;
  onMount(() => {
    metadata = {
      title: 'Conteúdo não encontrado',
      description: 'A página que você procura não foi encontrada.',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Página não encontrada',
      subTitle: 'O conteúdo que você procurou não foi encontrado',
      backgroundType: 'pattern',
      backgroundColor: '#ffcd42',
      backgroundImage: '/images/banners/brazil-pattern.svg',
    };
  });
</script>

<Meta {metadata} />
<HeaderBar />
<MainSearch {...mainSearchParams} />
<Footer />
