import { writable } from 'svelte/store';
export const isAuthenticated = writable(false);
export const user = writable({});
export const popupOpen = writable(false);
export const error = writable();

const debug = false;

let apiUrl = "https://api2.listabrasil.com";

if ( debug ) apiUrl = "http://localhost:7071";

const settings = writable({
    mapReady: false,
    apiUrl: apiUrl,
    
} );

const lists = writable({
    filtered: []
});

export {
     settings, lists
}