<div id="logo-container">
  <div id="top-bottom">
    <svg
      class="top-item"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124.8 72.1"
    >
      <path
        fill="#fff"
        d="M62.2 36.1L124.8 72V36.1L63 .2l-.4-.2L.1 36 0 72.1z"
      />
    </svg>
    <svg
      class="bottom-item"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124.8 72.1"
    >
      <path
        fill="#fff"
        d="M62.2 36.1L124.8 72V36.1L63 .2l-.4-.2L.1 36 0 72.1z"
      />
    </svg>
  </div>
  <svg
    class="middle-item"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 62.4 72.1"
  >
    <path d="M0 17.8L31 0l31.4 17.8v36.3l-31.1 18L0 53.5V17.8z" fill="#fff" />
  </svg>
</div>

<style type="text/scss">
  @import '../style/theme.scss';
  $animation-duration: 3s;
  svg path {
    animation-name: colour;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
  }
  #logo-container {
    margin: 40px auto;
    position: relative;
    width: 100px;
    height: 125px;
  }
  #top-bottom {
    position: relative;
    width: 100%;
    height: 100%;
    animation-name: rotate;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
  }
  .top-item {
    position: absolute;
    top: 0;
    left: 0;
  }
  .middle-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: auto;
  }
  .bottom-item {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
  }

  @keyframes colour {
    0% {
      fill: $main-color;
    }
    20% {
      fill: #f4b400;
    }
    40% {
      fill: #2d91d6;
    }
    100% {
      fill: $main-color;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
