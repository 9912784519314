<script>
  import { lists } from '../utils/store';
  import { onMount } from 'svelte';
  export let businesses;
  let isDistance = false;
  let businessesOriginal = [];
  let businessesByDistance = [];

  onMount(async () => {
    if (businessesOriginal.length === 0)
      businessesOriginal = businesses.slice();
    if (businessesByDistance.length === 0)
      businessesByDistance = businesses.slice();
    filter('nearest'); //Start page ordering by distance
  });

  function select(event) {
    let buttons = document
      .getElementsByClassName('filters')[0]
      .getElementsByTagName('button');
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }
    event.srcElement.classList.add('active');
  }

  function filter(action) {
    switch (action) {
      case 'nearest':
        if (!isDistance) {
          sortByDistance(businessesByDistance);
          $lists.filtered = businessesByDistance;
        } else {
          $lists.filtered = businessesByDistance;
        }
        break;
      default:
        $lists.filtered = businessesOriginal;
    }
  }
  function sortByDistance(itemsToSort) {
    isDistance = true;
    itemsToSort.sort(function (a, b) {
      let valA = a.Distance;
      let valB = b.Distance;
      return valA - valB;
    });
  }
</script>

<section>
  <div class="container">
    <ul class="left filters">
      <li id="nearest">
        <button
          class="active"
          on:click={select}
          on:click|preventDefault={() => filter('nearest')}
          >Mais próximos</button
        >
      </li>
      <li>
        <button on:click={select} on:click|preventDefault={() => filter('all')}>
          Mais novos
        </button>
      </li>
    </ul>
  </div>
</section>

<style lang="scss">
  @import '../style/theme.scss';
  .container {
    margin-bottom: 10px;
  }
  section {
    border-bottom: solid 1px $gray-color;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  ul {
    width: fit-content;
    display: flex;
    li button {
      white-space: nowrap;
      background: transparent;
      cursor: pointer;
      padding: 5px 15px;
      font-size: 0.92rem;
      position: relative;
      border: 1px solid $gray-color;
      &:hover:not(.active):not(.svg-active) {
        background-color: $gray-color;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .left {
    margin-top: 5px;
  }
  .left li:last-child > button {
    border-radius: 0px 3px 3px 0px;
  }
  .left li:first-child > button {
    border-radius: 3px 0px 0px 3px;
  }
  .active {
    background-color: $dark-color;
    color: #fff;
  }
</style>
