<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Teaser from '../components/Teaser.svelte';
  let metadata, mainSearchParams, favourites;
  onMount(() => {
    metadata = {
      title: 'Termos e condições de uso',
      description:
        'Ao visitar o Lista Brasil você concorda com nossos termos e condições de uso',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Termos e condições de uso',
      showSearch: false,
      height: '350px',
    };
  });
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section>
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Condições de uso do Lista Brasil</h2>
      <p>
        Ao visitar o Lista Brasil você concorda com nossos termos e condições de
        uso
      </p>
    </div>
  </div>
  <div class="container text-section">
    <p>
      Os termos "<strong>Lista Brasil</strong>" ou "<strong>nós</strong>" se
      referem ao proprietário deste website. E "<strong>você</strong>" refere-se
      ao usuário que está visitando nosso webiste.
    </p>
    <h3>O visitante deste website está sujeito aos seguintes termos de uso:</h3>
    <ul>
      <li>
        O conteúdo das páginas deste website é somente informativo e está
        sujeito a alterações sem aviso prévio
      </li>
      <li>
        O <strong>Lista Brasil</strong> não fornece qualquer garantia de precisão
        ou veracidade das informações fornecidas ou endossa qualquer serviço ou produto
        aqui anunciado.
      </li>
      <li>
        O uso de qualquer informação ou material contido neste website é
        inteiramente por sua conta e risco. É de sua responsabilidade
        assegurar-se que qualquer produto, serviço ou informação contida neste
        website atendam suas necessidades específicas.
      </li>
      <li>
        Este website contém informações que são de nossa propriedade, foram a
        nós fornecidas ou são públicas. Este material inclui mas não é limitado
        ao layout, design, aparência e gráficos. Reproduções são proibidas se
        não estiverem de acordo com a lei de direitos autorais.
      </li>
      <li>
        O uso não autorizado deste website pode resultar em ofença criminal.
      </li>
      <li>
        Este website contém links para outros websites, estes links são para sua
        conveniência somente. Isso não significa que nós endossamos estes
        websites. Nós não temos responsabilidade sobre o conteúdo de websites
        externos.
      </li>
    </ul>
    <p>
      Todos os esforços são feitos para manter este website funcionando
      normalmente. No entando o <strong>Lista Brasil</strong> não se responsabiliza
      pelo website estar temporariamente indisponível por razões além do nosso controle.
    </p>
  </div>
</section>
<Teaser />
<Footer />
