<script>
  import { Router, Route } from 'svelte-routing';
  import PageNotFound from './pages/PageNotFound.svelte';
  import { settings } from './utils/store';

  //PAGES
  import Index from './pages/Index.svelte';
  import Categories from './pages/Categories.svelte';
  import Category from './pages/Category.svelte';
  import Business from './pages/Business.svelte';
  import MyFavourites from './pages/MyFavourites.svelte';
  import TermsAndConditions from './pages/TermsAndConditions.svelte';
  import PrivacyAndPolicy from './pages/PrivacyAndPolicy.svelte';
  import Contact from './pages/Contact.svelte';
  import SignUp from './pages/SignUp.svelte';
  import Profile from './pages/Profile.svelte';

  export let url = '';

  console.log(
    "Welcome to\n.   .-. .-. .-. .-.   .-. .-. .-. .-. .-. .\n|    |  `-.  |  |-|   |(  |(  |-| `-.  |  |\n`-' `-' `-'  '  ` '   `-' ' ' ` ' `-' `-' `-'",
  );

  window.initMap = () => {
    $settings.mapReady = true;
  };

  history.pushState = new Proxy(history.pushState, {
    apply(target, thisArg, argumentsList) {
      scrollTo(0, 0);
      Reflect.apply(target, thisArg, argumentsList);
    },
  });
</script>

<svelte:head>
  <script
    defer
    async
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDzG0j9MzrGLgzXnlhhFSR_VyjHnD68yRE&libraries=places&language=pt_BR&callback=initMap">
  </script>

  <script
    defer
    async
    src="https://www.google.com/recaptcha/api.js?render=6LfX4LYaAAAAAIYvstctJYlCQ_Tf0aPKM-JUpwVt">
  </script>
</svelte:head>

<Router {url}>
  <main>
    <!--HOME PAGE-->
    <Route path="/" component={Index} />
    <!--Categories-->
    <Route
      path="/pt/:location/empresas-e-servicos/:geoinfo"
      component={Categories}
    />
    <Route
      path="/pt/:location/empresas-e-servicos/c/:categoryId/:categoryTitle/:geoinfo"
      component={Category}
    />
    <Route path="/pt/empresas-e-servicos/b/:id/:title" component={Business} />
    <Route path="/meus-favoritos" component={MyFavourites} />
    <Route path="/termos-e-condicoes-de-uso" component={TermsAndConditions} />
    <Route path="/politica-de-privacidade" component={PrivacyAndPolicy} />
    <Route path="/entre-em-contato" component={Contact} />
    <Route path="/anuncie-no-lista-brasil" component={SignUp} />
    <!--Protected-->
    <Route path="/perfil" component={Profile} />
    <!--404-->
    <Route path="/conteudo-nao-encontrado" component={PageNotFound} />
    <Route path="**" component={PageNotFound} />
  </main>
</Router>

<style lang="scss" global>
  @import './style/reset.scss';
  @import './style/global.scss';
  @import './style/swiper.scss';
</style>
