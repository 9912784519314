<script>
  import { IconDocument, IconHeart, IconUser } from './icons';
  import { link } from 'svelte-routing';
  import { onMount } from 'svelte';
  import auth from '../authService.js';
  import { isAuthenticated, user } from '../utils/store.js';

  let auth0Client;
  export let isAlwaysSticky = false;
  onMount(async () => {
    const header = document.getElementsByTagName('header')[0];
    if (isAlwaysSticky) {
      header.classList.add('sticky');
      header.classList.add('not-fixed');
    } else {
      window.onscroll = function () {
        stickyHeader();
      };

      const sticky = header.offsetTop;

      function stickyHeader() {
        window.pageYOffset > sticky
          ? header.classList.add('sticky')
          : header.classList.remove('sticky');
      }
    }

    auth0Client = await auth.createClient();
    isAuthenticated.set(await auth0Client.isAuthenticated());
    user.set(await auth0Client.getUser());

    if ($isAuthenticated) {
      console.log($user);
    } else {
      console.log('Not authenticated');
    }
  });

  function login() {
    auth.loginWithPopup(auth0Client);
  }
</script>

<IconDocument /><IconHeart /><IconUser />
<header>
  <nav class="container">
    <a href="/" title="Voltar para página inicial" use:link>
      <img
        class="sticky-logo logo"
        src="/images/logo_dark.svg"
        alt="Lista Brasil logo"
        width="232.73"
        height="55"
      />
      <img
        class="logo"
        width="232.73"
        height="55"
        src="/images/logo-white.svg"
        alt="Lista Brasil logo"
      />
    </a>
    <ul>
      <li>
        <a
          href="/anuncie-no-lista-brasil"
          title="Anuncie no Lista Brasil"
          use:link
          ><svg class="icon only-small"><use xlink:href="#icon-document" /></svg
          >
          <span class="only-big">Anunciar</span></a
        >
      </li>
      <li>
        {#if $isAuthenticated}
          <a href="/perfil" title="Minha conta">
            <svg class="icon"><use xlink:href="#icon-user" /></svg>
          </a>
        {:else}
          <a href="/#login" title="Minha conta" on:click={login}>
            <svg class="icon"><use xlink:href="#icon-user" /></svg>
          </a>
        {/if}
      </li>
      <li>
        <a href="/meus-favoritos" title="Meus favoritos" use:link>
          <svg class="icon"><use xlink:href="#icon-heart" /></svg>
        </a>
      </li>
    </ul>
  </nav>
</header>

<style type="text/scss">
  @import '../style/theme.scss';
  .icon {
    fill: white;
  }
  .logo {
    height: 55px;
    width: auto;
  }
  header {
    padding: 10px 0;
    will-change: transform;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    z-index: 5;

    :global(a) {
      color: #fff;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
    }

    li {
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
  img.sticky-logo {
    display: none;
  }
  :global(.not-fixed) {
    position: inherit !important;
  }
  :global(.sticky) {
    background-color: $dark-color;

    img {
      display: none;
    }

    img.sticky-logo {
      display: block;
    }
  }
</style>
