<script>
  import { link } from 'svelte-routing';
  import { settings } from '../utils/store';
  import Card from './Card.svelte';
  import { onMount } from 'svelte';
  import Loading from './Loading.svelte';
  import SwiperCore, { Pagination, A11y } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import { ToSeo } from '../utils/ToSeo.svelte';
  import 'swiper/swiper.scss';
  const searchParamsStorageName = 'lista-search';
  SwiperCore.use([Pagination, A11y]);

  let city = 'londres';
  let geoInfo = '@51.509865,-0.118092';
  let categoriesPopular;

  function getStorage() {
    let storageItem = JSON.parse(localStorage.getItem(searchParamsStorageName));
    if (!storageItem) storageItem = {};
    return storageItem;
  }

  onMount(async () => {
    let searchParams = getStorage();
    if (Object.keys(searchParams).length > 0) {
      geoInfo = `@${searchParams.lat},${searchParams.lng}`;
      city = ToSeo(searchParams.placeAddress);
    }
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'x') subdomain = 'www';
    await fetch(`${$settings.apiUrl}/categories/${subdomain}/popular`)
      .then((r) => r.json())
      .then((data) => {
        categoriesPopular = data.Data;
      });
  });

  const options = {
    pagination: { clickable: true, el: '.pagination-categories' },
    observer: true,
    observeParents: true,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      500: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };
</script>

<section class="section-gray">
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Categorias populares</h2>
      <p>Essas são as categorias mais visitadas do Lista Brasil</p>
    </div>
    <div class="swiper-container">
      <Swiper {...options}>
        {#if categoriesPopular}
          {#each categoriesPopular as category}
            <SwiperSlide>
              <Card
                Url="/pt/{city}/empresas-e-servicos/c/{category.ID}/{category.Url.replace(
                  'internal-to-be-replaced',
                  city,
                )}/{geoInfo}"
                Icon={category.Icon}
                Name={category.Name}
              />
            </SwiperSlide>
          {/each}
        {:else}
          <Loading />
        {/if}
      </Swiper>
    </div>
    <div class="swiper-pagination pagination-categories" />
    <div class="view-all-categories">
      <a
        href="/pt/{city}/empresas-e-servicos/{geoInfo}"
        class="btn btn-primary"
        use:link>Ver todas</a
      >
    </div>
  </div>
</section>

<style type="text/scss">
  .view-all-categories {
    position: relative;
    .btn-primary {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .swiper-container {
    position: relative;
  }
  @media only screen and (max-width: 500px) {
    .btn-primary {
      width: 100%;
      text-align: center;
    }
    .swiper-pagination {
      visibility: hidden;
    }
  }
</style>
