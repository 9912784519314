<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import { settings } from '../utils/store';
  import MainSearch from '../components/MainSearch.svelte';
  import Teaser from '../components/Teaser.svelte';
  import Footer from '../components/Footer.svelte';
  import Loading from '../components/Loading.svelte';
  import Card from '../components/Card.svelte';
  import RouteTest from '../utils/RouteTest.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  let metadata, mainSearchParams, categories, routeData;

  export let location;
  export let geoinfo;

  routeData = {
    location: location,
    geoInfo: geoinfo,
  };

  onMount(async () => {
    metadata = {
      title: 'Empresas e serviços',
      description: 'Lista de todas as categorias disponíveis no Lista Brasil',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Empresas e serviços',
      subTitle: 'Use a busca ou selecione uma categoria',
      height: '440px',
    };
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'x') subdomain = 'www';
    await fetch(`${$settings.apiUrl}/categories/${subdomain}/all`)
      .then((r) => r.json())
      .then((data) => {
        categories = data.Data;
      });
  });
</script>

<RouteTest {...routeData} />
<Meta {metadata} />
<HeaderBar />
<MainSearch {...mainSearchParams} />
<section class="section-gray">
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Categorias disponíveis</h2>
      <p>Escolha por categoria, a empresa ou serviço que você precisa</p>
    </div>
    {#if categories}
      <div class="items-list">
        {#each categories as category}
          <Card
            Url="/pt/{location}/empresas-e-servicos/c/{category.ID}/{category.Url.replace(
              'internal-to-be-replaced',
              location,
            )}/{geoinfo}"
            Icon={category.Icon}
            Name={category.Name}
          />
        {/each}
      </div>
    {:else}
      <Loading />
    {/if}
  </div>
</section>
<Teaser />
<Footer />

<style lang="scss">
  .items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-column-gap: 20px;
  }
</style>
