<script>
  import { link } from 'svelte-routing';
  import MainSearch from './MainSearch.svelte';
  import { IconSearch } from './icons';
  export let links = [];
  export let pageTitle = '';
  let mainSearchParams = {
    height: '100px',
    backgroundImage: '',
  };
  function showSearch(event) {
    let bt = event.srcElement;
    if (bt.tagName !== 'BUTTON') {
      bt = bt.closest('.bt-icon-toggle');
    }
    bt.classList.toggle('svg-active');
    let searchContainer = document.getElementById('search-container');
    searchContainer.classList.toggle('search-active');
  }
</script>

<IconSearch />
<section class="section-gray">
  <div class="container">
    <div class="breadcrumbs">
      <ol itemscope itemtype="https://schema.org/BreadcrumbList">
        {#each links as l}
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
          >
            <a itemprop="item" href={l.url} use:link>
              <span itemprop="name">{l.title}</span></a
            >
            <meta itemprop="position" content="1" />
          </li>
        {/each}
      </ol>
      <h2>{pageTitle}</h2>
    </div>
    <button
      id="showSearch"
      on:click|preventDefault={showSearch}
      class="bt-icon-toggle"
      ><svg class="icon"><use xlink:href="#icon-search" /></svg></button
    >
  </div>
</section>
<div id="search-container">
  <MainSearch {...mainSearchParams} />
</div>

<style lang="scss">
  h2 {
    font-weight: 400;
    font-size: 1.3rem;
    white-space: nowrap;
    margin-top: 10px;
  }
  ol,
  li {
    display: flex;
  }
  li a {
    font-size: 0.85rem;
  }
  li:not(:last-child) a:after {
    content: '›';
    padding: 0 5px;
    display: inline-block;
  }

  #search-container {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.2s ease-out;
  }
  :global(.search-active) {
    height: 100px !important;
    overflow: visible !important;
  }
  .bt-icon-toggle {
    cursor: pointer;
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: inherit;
    .icon {
      width: 30px;
      height: 30px;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }
  .container {
    min-height: 64px;
  }
  @media (max-width: 800px) {
    :global(.search-active) {
      height: 220px !important;
    }
  }
  @media (max-width: 500px) {
    .breadcrumbs {
      display: none;
    }
  }
</style>
