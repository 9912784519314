<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import { settings } from '../utils/store';
  import PopularCategories from '../components/PopularCategories.svelte';
  import BusinessList from '../components/BusinessList.svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Teaser from '../components/Teaser.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  let metadata, mainSearchParams;
  let businessListData;
  onMount(async () => {
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'x') subdomain = 'www';
    await fetch(`${$settings.apiUrl}/business/${subdomain}/highlights`)
      .then((r) => r.json())
      .then((data) => {
        businessListData = data.Data;
      });
    metadata = {
      title: 'O melhor do Brasil pelo mundo',
      description:
        'No Lista Brasil voc&#234; encontra o que h&#225; de melhor do Brasil pelo mundo. Encontre os melhores produtos e servi&#231;os para brasileiros. Registre-se e an&#250;ncie seu produto ou servi&#231;o gr&#225;tis.',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Encontre e conheça',
      subTitle: 'O que há de melhor do Brasil pelo mundo',
    };
  });
</script>

<Meta {metadata} />
<HeaderBar />
<MainSearch {...mainSearchParams} />
<PopularCategories />
{#if businessListData}
  <section>
    <div class="container">
      <div class="header-two left">
        <span><em /></span>
        <h2>Destaques</h2>
        <p>Empresas e serviços em destaque</p>
      </div>
    </div>
    <BusinessList {businessListData} />
  </section>
{/if}
<Teaser />
<Footer />
