<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Teaser from '../components/Teaser.svelte';
  import { settings } from '../utils/store';
  let metadata, mainSearchParams;

  let showFormErrors;
  let emailSent;
  let contactName;
  let contactEmail;
  let contactEmailPlaceholder;
  let contactTelephone;
  let contactMessage;

  async function doPost() {
    showFormErrors = true;
    if (!contactEmail) {
      contactEmailPlaceholder = 'Email é necessário';
    } else if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        contactEmail,
      )
    ) {
      //Valid email address
    } else {
      contactEmail = null;
      contactEmailPlaceholder = 'Email inválido';
    }

    if (contactName && contactEmail && contactMessage) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute('6LfX4LYaAAAAAIYvstctJYlCQ_Tf0aPKM-JUpwVt', {
            action: 'contact',
          })
          .then(function (token) {
            finishSubmit(token);
          });
      });
    }
  }

  async function finishSubmit(token) {
    const res = await fetch(`${$settings.apiUrl}/contact-us`, {
      method: 'POST',
      body: JSON.stringify({
        name: contactName,
        email: contactEmail,
        telephone: contactTelephone,
        message: contactMessage,
        url: window.location.href,
        recaptchaToken: token,
      }),
    });

    const result = await res.json();
    if (result.response) {
      emailSent = result.response;
    } else {
      console.log(result);
    }
  }

  onMount(() => {
    contactEmailPlaceholder = 'Email';
    metadata = {
      title: 'Entre em contato',
      description:
        'Envie seu comentário, dúvida ou sugestão para o Lista Brasil',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Entre em contato',
      showSearch: false,
      height: '350px',
    };
  });
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section>
  <div class="container">
    <div class="center header-two">
      <span><em /></span>
      <h2>Entre em contato conosco</h2>
      <p>Envie seu comentário, dúvida ou sugestão para o Lista Brasil</p>
    </div>
  </div>
  <div class="container">
    <div class="contact-form">
      <form>
        {#if emailSent}
          <div class="confirmation-message">
            Sua mensagem foi enviada com sucesso, obrigado!
          </div>
        {:else}
          <input
            type="text"
            bind:value={contactName}
            id="name"
            placeholder={showFormErrors && !contactName
              ? `Nome é necessário`
              : `Nome`}
            class:error={showFormErrors && !contactName}
          />
          <input
            type="email"
            bind:value={contactEmail}
            id="email"
            placeholder={contactEmailPlaceholder}
            class:error={showFormErrors && !contactEmail}
          />
          <input
            type="tel"
            bind:value={contactTelephone}
            id="telephone"
            placeholder="Telefone"
          />
          <textarea
            id="message"
            bind:value={contactMessage}
            placeholder={showFormErrors && !contactMessage
              ? `Mensagem é necessária`
              : `Mensagem`}
            class:error={showFormErrors && !contactMessage}
          />
          <button class="btn btn-primary" on:click|preventDefault={doPost}
            >Enviar</button
          >
          <div class="small">
            Lista Brasil é protegido por reCAPTCHA. Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            e
            <a href="https://policies.google.com/terms">Terms of Service</a>
            são aplicados.
          </div>
        {/if}
      </form>
    </div>
  </div>
</section>
<Teaser />
<Footer />

<style type="text/scss">
  @import '../style/theme.scss';
  .confirmation-message {
    text-align: center;
    margin-top: 50px;
    text-transform: uppercase;
    color: $main-color;
  }
  .small {
    text-align: center;
    padding: 10px;
    color: darken($gray-color, 25%);
  }

  .btn {
    width: 100%;
  }

  form {
    padding: 20px;
    input,
    textarea {
      border: dotted 2px darken($gray-color, 10%);
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      &:focus {
        outline: none;
        border-color: $main-color;
      }
    }
    .error {
      border-color: red !important;
    }
    textarea {
      resize: vertical;
      height: 120px;
    }
    margin-top: 30px;
  }
</style>
