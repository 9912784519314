<script>
  import { link } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { settings } from '../utils/store';
  import { ToSeo } from '../utils/ToSeo.svelte';

  const date = new Date();
  const year = date.getFullYear();

  let firstHalf = [];
  let secondHalf = [];

  export let geoinfo = '@51.509865,-0.118092';
  export let location = 'londres';

  let categoriesPopular;

  const searchParamsStorageName = 'lista-search';
  function getStorage() {
    let storageItem = JSON.parse(localStorage.getItem(searchParamsStorageName));
    if (!storageItem) storageItem = {};
    return storageItem;
  }

  onMount(async () => {
    let searchParams = getStorage();
    if (Object.keys(searchParams).length > 0) {
      geoinfo = `@${searchParams.lat},${searchParams.lng}`;
      location = ToSeo(searchParams.placeAddress);
    }

    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'x') subdomain = 'www';
    await fetch(`${$settings.apiUrl}/categories/${subdomain}/popular`)
      .then((r) => r.json())
      .then((data) => {
        categoriesPopular = data.Data;
        const half = Math.ceil(categoriesPopular.length / 2);

        firstHalf = categoriesPopular.splice(0, half);
        secondHalf = categoriesPopular.splice(-half);
      });
  });
</script>

<section>
  <div class="container">
    <div class="links-list">
      <ul>
        <li><h4>Links úteis</h4></li>
        <!--<li>
          <a href="/sobre-o-listabrasil" use:link>Sobre o Lista Brasil</a>
        </li>-->
        <li>
          <a href="/anuncie-no-lista-brasil" use:link>Anuncie no Lista Brasil</a
          >
        </li>
        <li>
          <a href="/pt/{location}/empresas-e-servicos/{geoinfo}" use:link
            >Todas as categorias</a
          >
        </li>
        <li>
          <a href="#a" use:link>Lista Brasil - Explícito</a>
        </li>
      </ul>
      {#if firstHalf}
        <ul>
          <li><h4>Categorias populares</h4></li>
          {#each firstHalf as category}
            <li>
              <a
                href="/pt/{location}/empresas-e-servicos/c/{category.ID}/{category.Url.replace(
                  'internal-to-be-replaced',
                  location,
                )}/{geoinfo}">{category.Name}</a
              >
            </li>
          {/each}
        </ul>
      {/if}
      {#if secondHalf}
        <ul>
          <li><h4>Outras categorias</h4></li>
          {#each secondHalf as category}
            <li>
              <a
                href="/pt/{location}/empresas-e-servicos/c/{category.ID}/{category.Url.replace(
                  'internal-to-be-replaced',
                  location,
                )}/{geoinfo}">{category.Name}</a
              >
            </li>
          {/each}
        </ul>
      {/if}
      <ul>
        <li><h4>Suporte ao cliente</h4></li>
        <li><a href="/perfil">Minha conta</a></li>
        <li><a href="/entre-em-contato" use:link>Entre em contato</a></li>
        <!--<li><a href="/ajuda" use:link>Ajuda</a></li>-->
      </ul>
    </div>
    <hr />
    <div class="small-links">
      <ul>
        <li>
          <a href="/termos-e-condicoes-de-uso" use:link>Termos e condições</a>
        </li>
        <li><a href="/politica-de-privacidade" use:link>Privacidade</a></li>
        <li><a href="/" use:link>&copy; {year} - Lista Brasil</a></li>
      </ul>
    </div>
  </div>
</section>

<style type="text/scss">
  @import '../style/theme.scss';
  .links-list {
    display: grid;
    grid-template-columns: 1fr;
    ul:nth-child(1),
    ul:nth-child(2),
    ul:nth-child(3) {
      display: none;
    }
    a {
      padding: 4px 10px 4px 0;
      transition: all 0.3s ease-in-out;
      display: inline-block;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        transform: translate(5px, 0);
      }
    }
  }

  .small-links {
    font-size: 0.9em;
    padding-bottom: 30px;
    ul {
      width: 100%;
      text-align: center;
    }
    li {
      display: inline-block;
      margin-right: 20px;
    }
  }
  section {
    background-color: $dark-color;
    color: #fff;
    font-size: 0.9em;
    padding-top: 60px;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
  }
  hr {
    margin: 20px 0;
    border-color: rgba(255, 255, 255, 0.15);
  }

  @media only screen and (min-width: 500px) {
    .links-list {
      grid-template-columns: repeat(2, 1fr);
      ul:nth-child(1) {
        display: block;
      }
    }
  }

  @media only screen and (min-width: 750px) {
    .links-list {
      grid-template-columns: repeat(3, 1fr);
      ul:nth-child(2) {
        display: block;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    .links-list {
      grid-template-columns: repeat(4, 1fr);
      ul:nth-child(3) {
        display: block;
      }
    }
  }
</style>
