<script>
  export let location;
  export let id;
  export let url;
  export let geoInfo;

  if (
    (location !== undefined && !/^[a-z0-9]+(-[a-z0-9]+)*$/.test(location)) ||
    (url !== undefined && !/^[a-z0-9]+(-[a-z0-9]+)*$/.test(url)) ||
    (geoInfo !== undefined &&
      !/^[@][-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
        geoInfo,
      )) ||
    (id !== undefined && isNaN(id))
  ) {
    window.location.href = '/conteudo-nao-encontrado';
  }
</script>
