<script context="module">
  export function ToSeo(url) {
    let encodedUrl = url.toString().toLowerCase();
    encodedUrl = encodedUrl.split(/\&+/).join('-e-');
    encodedUrl = encodedUrl.replace(/\{0\}/g, 'internal-to-be-replaced');
    encodedUrl = encodedUrl.replace(/'/g, '');
    encodedUrl = encodedUrl.replace(/ç/g, 'c');
    encodedUrl = encodedUrl.replace(/â/g, 'a');
    encodedUrl = encodedUrl.replace(/ã/g, 'a');
    encodedUrl = encodedUrl.replace(/ä/g, 'a');
    encodedUrl = encodedUrl.replace(/à/g, 'a');
    encodedUrl = encodedUrl.replace(/á/g, 'a');
    encodedUrl = encodedUrl.replace(/ê/g, 'e');
    encodedUrl = encodedUrl.replace(/ë/g, 'e');
    encodedUrl = encodedUrl.replace(/è/g, 'e');
    encodedUrl = encodedUrl.replace(/é/g, 'e');
    encodedUrl = encodedUrl.replace(/î/g, 'i');
    encodedUrl = encodedUrl.replace(/ï/g, 'i');
    encodedUrl = encodedUrl.replace(/ì/g, 'i');
    encodedUrl = encodedUrl.replace(/í/g, 'i');
    encodedUrl = encodedUrl.replace(/ô/g, 'o');
    encodedUrl = encodedUrl.replace(/õ/g, 'o');
    encodedUrl = encodedUrl.replace(/ö/g, 'o');
    encodedUrl = encodedUrl.replace(/ò/g, 'o');
    encodedUrl = encodedUrl.replace(/ó/g, 'o');
    encodedUrl = encodedUrl.replace(/û/g, 'u');
    encodedUrl = encodedUrl.replace(/ü/g, 'u');
    encodedUrl = encodedUrl.replace(/ù/g, 'u');
    encodedUrl = encodedUrl.replace(/ú/g, 'u');
    encodedUrl = encodedUrl.replace(/ý/g, 'y');
    encodedUrl = encodedUrl.replace(/ñ/g, 'n');
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join('-');
    encodedUrl = encodedUrl.split(/-+/).join('-');
    encodedUrl = encodedUrl.replace(/(^-)|(-$)/g, '');

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim('-');

    return encodedUrl;
  }
</script>
