<script>
  import Meta from '../utils/Meta.svelte';
  import { onMount } from 'svelte';
  import MainSearch from '../components/MainSearch.svelte';
  import Footer from '../components/Footer.svelte';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Teaser from '../components/Teaser.svelte';
  import CardBusiness from '../components/CardBusiness.svelte';
  let metadata, mainSearchParams, favourites;
  onMount(() => {
    favourites = JSON.parse(localStorage.getItem('lista-favourites'));
    if (!favourites) favourites = [];

    metadata = {
      title: 'Meus favoritos',
      description: 'Aqui você entra os seus serviços favoritos.',
      url: window.location.href,
    };
    mainSearchParams = {
      title: 'Meus favoritos',
      subTitle: 'Aqui você entra os seus serviços favoritos',
      backgroundType: 'pattern',
      backgroundColor: '#ae0000',
      backgroundImage: '/images/banners/hearts-pattern.svg',
      showSearch: false,
      height: '350px',
    };
  });
</script>

{#if metadata}
  <Meta {metadata} />
{/if}
<HeaderBar />
{#if mainSearchParams}
  <MainSearch {...mainSearchParams} />
{/if}
<section>
  <div class="container">
    <div class="header-two left">
      <span><em /></span>
      <p>Seus serviços e empresas favoritas</p>
    </div>
    <div id="items-container" class="items-list">
      {#if favourites}
        {#each favourites as business}
          <CardBusiness {business} />
        {/each}
      {/if}
    </div>
  </div>
</section>
<Teaser />
<Footer />

<style lang="scss">
  @import '../style/theme.scss';

  .items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 20px;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 800px) {
    .items-list {
      grid-template-columns: 1fr;
    }
  }
</style>
