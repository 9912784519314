<script>
  export let metadata = {};
</script>

<svelte:head>
  {#if metadata.title}
    <title>{metadata.title} | Lista Brasil</title>
    <meta name="title" content={metadata.title} />
    <meta property="og:title" content={metadata.title} />
  {/if}

  {#if metadata.description}
    <meta name="description" content={metadata.description} />
    <meta property="og:description" content={metadata.description} />
  {/if}

  {#if metadata.url}
    <meta property="og:url" content={metadata.url} />
  {/if}

  {#if metadata.keywords}
    <meta name="keywords" content={metadata.keywords} />
  {/if}

  <meta
    property="og:image"
    content="https://listabrasil.com/images/banners/og-image.png"
  />
  <meta property="og:image:alt" content="Lista Brasil" />

  <meta property="og:type" content="website" />
</svelte:head>
