<script context="module">
  export function GetDirections(business, origin) {
    business.mapUrl = '';
    let businessAddress = '';
    if (
      business.City !== '' &&
      business.City !== undefined &&
      business.Postcode !== '' &&
      business.Postcode !== undefined
    ) {
      businessAddress = businessAddress + ' ' + business.City;
      businessAddress = businessAddress + ' ' + business.Postcode;
      if (business.Address !== '' && business.Address !== undefined)
        businessAddress = businessAddress + ' ' + business.Address;
      businessAddress = encodeURI(businessAddress);
      if (origin) {
        businessAddress =
          businessAddress + `&origin=${origin.replace('@', '')}`;
      }
      business.mapUrl = `https://www.google.com/maps/dir/?api=1&destination=${businessAddress}&layer=none`;
    }
    return business;
  }
</script>
