<script>
  import { onMount } from 'svelte';

  import SwiperCore, { Pagination, A11y } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import CardBusiness from './CardBusiness.svelte';
  import 'swiper/swiper.scss';
  SwiperCore.use([Pagination, A11y]);

  export let businessListData;

  const options = {
    pagination: { clickable: true, el: '.pagination-highlights' },
    observer: true,
    observeParents: true,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      700: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1100: {
        slidesPerView: 3.2,
        spaceBetween: 20,
      },
    },
  };

  onMount(async () => {});
</script>

<section>
  <div class="container">
    {#if businessListData}
      <Swiper {...options}>
        {#each businessListData as business}
          <SwiperSlide>
            <CardBusiness {business} />
          </SwiperSlide>
        {/each}
      </Swiper>{/if}
    <div class="swiper-pagination pagination-highlights" />
  </div>
</section>

<style type="text/scss">
  @import '../style/theme.scss';
  section {
    background: #fff;
  }
</style>
